<template>
  <div class="mainBody">
    <div class="mainTop">
      <div class="imgTitle">
        <img src="./imgs/023.png" alt="" />
      </div>
      <div class="userTitle">
        <div class="support" @click="ShowSupport = !ShowSupport">
          <div class="supportBox" v-if="ShowSupport">
            <div class="smallSanJ"></div>
            <div class="smallCom"></div>
            <div class="supportData">
              <div class="valueData">
                <span> 网页用于学习和测试，无任何套路， </span>
                <span> 底下输入框输入就能直接用~ </span>
                <span> 禁止发布、传播任何违法、违规内容。 </span>
                <span> 服务器昂贵，算力昂贵， </span>
                <span> 每人捐点、服务就能永久存活下去！ </span>
                <span> 如果你觉得我们做的好。 </span>
              </div>
              <div class="supportImg">
                <img src="../../../public/imgs/supportImg.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="about" @click="ShowAbout = !ShowAbout">
          <div class="aboutBox" v-if="ShowAbout">
            <div class="smallSanJ"></div>
            <div class="smallCom"></div>
            <div style="height: 100%; width: 100%">
              <aboutItem :valueImgUrl="valueImgUrl"></aboutItem>
            </div>
          </div>
        </div>
        <div class="userView" style="width: 80px; display: flex; justify-content: center; align-items: center">
          <div v-if="hasLogin">
            <div class="userImg" style="cursor: pointer">
              <n-dropdown trigger="click" :options="options" @select="handleSelect">
                <img src="./imgs/008.png" alt="" />
              </n-dropdown>
            </div>
          </div>
          <div class="userInfo" v-else @click="loginFun">登录</div>
        </div>
      </div>
    </div>
    <div class="mainBottom" ref='myMainBottom'>
      <div class="mainLeft" :style="{ width: WidthSize + 'px', position: 'relative' }">
        <div class="ViewWord" style="width: 100%; height: 100%; display: flex; flex-direction: column" v-if="sendType === 'world'">
          <div class="replyView defaultQue" :style="{ height: HeightSize + 'px' }">
            <div class="defaultTitle">
              <div class="TitleValue">可以试着类似这样问我</div>
            </div>
            <div class="defaultValue">
              <div class="valueBox" v-for="(item, index) in valueArray" :key="index" @click="sendValue = item.text">
                <n-ellipsis :line-clamp="1">
                  {{ item.text }}
                </n-ellipsis>
              </div>
            </div>
          </div>
          <div class="resizeHeightDrop" @mousedown="moveHeightDown"></div>
          <div style="width: 100%;flex: 1 1 0%;position: relative;overflow: auto;padding: 5px 0px;display: flex;flex-direction: column;justify-content: space-between;">
            <!-- <div class="wxCode"> -->
            <div class="replyBox">
              <div class="AddNewTalk" @click="AddNewTalk">新的聊天</div>
              <div class="TalkHistory" style="width: 100%; height: 100%">
                <div
                  :class="nowUserTalk && nowUserTalk.id == item.id ? 'HistoryItem HistoryItemChecked' : 'HistoryItem'"
                  style="cursor: pointer; width: 100%; height: 50px; display: flex; align-items: center; background-color: #232323; border-radius: 10px; margin: 10px 0; color: white"
                  v-for="(item, index) in historyTalkTitle"
                  :key="item.id"
                >
                  <div @click="changeNowTalk(item)" style="width: 90%;height: 100%; display: flex; align-items: center; justify-content: center">
                    <img src="./imgs/022.png" style="width: 18px; height: 18px; margin: 0 20px" alt="" />
                    <n-ellipsis style="width: calc(100% - 100px)">
                      {{ item.title || "新的回话" }}
                    </n-ellipsis>
                  </div>
                  <n-popconfirm positive-text="确定" negative-text="取消" @positive-click="deleteOneTalk(item)" @negative-click="handleNegativeClick(index)">
                    <template #trigger>
                      <img src="./imgs/021.png" style="width: 18px; height: 18px" alt="" />
                    </template>
                    确认删除此对话！
                  </n-popconfirm>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center; color: white;height: 120px;">
              <img :src="daBaoUrl + qrCodeUrl" alt="" />
              加入社群
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="ImgView" style="width: 100%; height: 100%; display: flex; flex-direction: column" v-else>
          <div class="defaultImgQue" :style="{ height: HeightSize + 'px' }">
            <div class="defaultTitle">
              <div class="TitleValue">可以试着⽤这样的提⽰词⽣成图⽚</div>
            </div>
            <div class="defaultValue">
              <div class="valueBox" v-for="(item, index) in valueImgArray" :key="index">
                <n-tooltip placement="right" trigger="hover">
                  <template #trigger>
                    <div style="-webkit-line-clamp: 1;display: -webkit-inline-box;-webkit-box-orient: vertical;overflow: hidden;"  @click="sendValue = item.text">
                      {{ item.text }}
                    </div>
                  </template>
                  <div style="display: flex; flex-wrap: wrap; width: 300px">
                    <div>{{ item.text }}</div>
                    <img v-for="(item, index) in item.images" :key="index" :src="daBaoUrl + item" alt="" style="width: 150px; width: 150px; padding: 10px" />
                  </div>
                </n-tooltip>
              </div>
            </div>
          </div>
          <div class="resizeHeightDrop" @mousedown="moveHeightDown"></div>
          <div class="imgBoxSetting" style="width: 100%; flex: 1; position: relative">
            <div style="width: 100%;height: 100%;overflow: auto;padding: 10px 14px;position: absolute;display: flex;flex-direction: column;justify-content: space-between;">
              <div  style="flex: 1;overflow: auto;">
                <n-collapse arrow-placement="right">
                  <n-collapse-item name="1">
                    <template #header>
                      <div class="flexCenter" style="width: 80%; justify-content: flex-start"><img src="./imgs/014.png" style="height: 15px; width: 15px; margin-right: 5px" alt="" /> 风格</div>
                      <div style="width: 80%">
                        <div class="flexCenter" style="justify-content: flex-start; margin-left: 5%">
                          <img style="width: 28px; height: 28px" :src="nowCheckEdType.typeImg" alt="" />{{ nowCheckEdType.typeName }}
                        </div>
                      </div>
                    </template>
                    <div class="styleChoose" style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-around; align-items: center">
                      <div
                        :class="nowCheckEdType.typeId === item.typeId ? 'styleChild flexCenter checkedStyle' : 'styleChild flexCenter'"
                        style="width: 20%; opacity: 0.4; margin: 0 1%; flex-wrap: wrap"
                        v-for="(item, index) in styleArray"
                        :key="index"
                        @click="nowCheckEdType = item"
                      >
                        <img style="width: 100%" :src="item.typeImg" alt="" />
                        {{ item.typeName }}
                      </div>
                    </div>
                  </n-collapse-item>
                  <n-collapse-item name="2">
                    <template #header>
                      <div class="flexCenter"><img src="./imgs/015.png" style="height: 15px; width: 15px; margin-right: 5px" alt="" /> 图片上传(图生图)</div>
                    </template>
                    <div>
                      <div class="ImgFile" style="position: relative; display: flex; justify-content: center; align-items: center; padding: 10px">
                        <img
                          v-if="Base64Img !== ''"
                          :style="{ filter: `blur(${((100 - effectData) / 100) * 5}px)`, cursor: 'pointer' }"
                          @click="UploadClick"
                          width="100"
                          :src="Base64Img"
                          alt=""
                        />
                        <div v-else @click="UploadClick" style="width: 100px; height: 100px; background-color: antiquewhite; cursor: pointer"></div>
                        <div class="deleteBtn" style="position: absolute; right: 20px; top: 10px; cursor: pointer" @click="clearImg">
                          <img style="width: 18px; height: 18px" src="./imgs/021.png" alt="" />
                        </div>
                      </div>
                      <div class="effectData">
                        <div style="display: flex; justify-content: space-between">
                          <div>原片影响强度</div>
                          <div>{{ effectData }}%</div>
                        </div>
                        <n-slider v-model:value="effectData" :tooltip="false" :step="1" />
                      </div>
                    </div>
                  </n-collapse-item>
                  <n-collapse-item name="3">
                    <template #header>
                      <div class="flexCenter"><img src="./imgs/016.png" style="height: 15px; width: 15px; margin-right: 5px" alt="" /> 图片尺寸</div>
                    </template>
                    <div>
                      <div class="MarksArray" style="display: flex; justify-content: space-between">
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 0 ? 'Checked' : ''" style="height: 24px; width: 42px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 12.5 ? 'Checked' : ''" style="height: 24px; width: 36px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 25 ? 'Checked' : ''" style="height: 24px; width: 32px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 37.5 ? 'Checked' : ''" style="height: 24px; width: 30px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 50 ? 'Checked' : ''" style="height: 24px; width: 24px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 62.5 ? 'Checked' : ''" style="height: 30px; width: 24px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 75 ? 'Checked' : ''" style="height: 32px; width: 24px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 87.5 ? 'Checked' : ''" style="height: 36px; width: 24px; border: 1px solid #5b5b5b"></div>
                        </div>
                        <div style="width: 10%;display: flex;justify-content: center;">
                          <div :class="checkEdMarks == 100 ? 'Checked' : ''" style="height: 42px; width: 24px; border: 1px solid #5b5b5b"></div>
                        </div>
                      </div>
                      <div style="padding: 0 5px">
                        <n-slider v-model:value="checkEdMarks" :tooltip="false" :marks="sliderMarks" step="mark" @update:value="getImgFile"/>
                      </div>
                    </div>
                  </n-collapse-item>
                  <n-collapse-item name="4">
                    <template #header>
                      <div class="flexCenter"><img src="./imgs/017.png" style="height: 15px; width: 15px; margin-right: 5px" alt="" /> 高级选项</div>
                    </template>
                    <div class="HeightSetting" style="display: flex; flex-wrap: wrap">
                      <div class="HeightItem flexCenter" style="width: 50%; height: 40px">
                        <div style="width: 80px">宽度</div>
                        <div style="width: 50px">{{ checkEdMarksArray[checkEdMarks][0] }}</div>
                      </div>
                      <div class="HeightItem flexCenter" style="width: 50%; height: 40px">
                        <div style="width: 80px">提示强度</div>
                        <div style="width: 50px">Auto</div>
                      </div>
                      <div class="HeightItem flexCenter" style="width: 50%; height: 40px">
                        <div style="width: 80px">高度</div>
                        <div style="width: 50px">{{ checkEdMarksArray[checkEdMarks][1] }}</div>
                      </div>
                      <div class="HeightItem flexCenter" style="width: 50%; height: 40px">
                        <div style="width: 80px">生成步骤</div>
                        <div style="width: 50px">50</div>
                      </div>
                    </div>
                  </n-collapse-item>
                </n-collapse>
              </div>
              <div style="display: flex; flex-direction: column; align-items: center; color: white;height: 120px;">
                <img :src="daBaoUrl + qrCodeUrl" alt="" />
                加入社群
              </div>
            </div>
          </div>
        </div>
        <div class="resizeDrop" @mousedown="moveDown"></div>
      </div>
      <div class="mainRight">
        <div class="dialogueBox">
          <aboutItem v-if="nowUserTalk === null" :valueImgUrl="valueImgUrl"></aboutItem>
          <dialogueBox  v-else ref="myDialogueBox" :isTalkView='isTalkView' :nowUserTalk="nowUserTalk"></dialogueBox>
        </div>
        <div class="askViewBox">
          <div class="WordChose" style="cursor: pointer; padding: 0 0 0 40px; width: 88px; height: 48px; display: flex; justify-content: center; align-items: center" @click="changeSendType('img')">
            <img v-if="sendType == 'world'" style="width: 30px; height: 24px" src="./imgs/010.png" alt="" />
            <img v-else style="width: 48px; height: 48px" src="./imgs/012.png" alt="" />
          </div>
          <div class="ImgChose" style="cursor: pointer; padding: 0 20px; width: 88px; height: 48px; display: flex; justify-content: center; align-items: center" @click="changeSendType('world')">
            <img v-if="sendType == 'world'" style="width: 48px; height: 44px" src="./imgs/013.png" alt="" />
            <img v-else style="width: 30px; height: 30px" src="./imgs/009.png" alt="" />
          </div>
          <div class="inputArea" style="width: calc(100% - 270px)">
            <n-input type="textarea" style="width: 100%; height: 48px; color: white;" v-model:value="sendValue" placeholder="在这里开始与chat-AI对话吧" />
          </div>
          <div class="sendBtn" style="cursor: pointer; padding: 0 20px; height: 48px" @click="sendMessage">
            <img src="./imgs/011.png" style="width: 48px; height: 48px" alt="" />
          </div>
        </div>
      </div>
    </div>
    <login-model :showModal="showModal" ref="LoginModel" @closeModal="closeModal" @submit="submit"> </login-model>
    <n-upload style="visibility: hidden; position: absolute; z-index: -1" :default-upload="false" @before-upload="beforeUpload" @change="handleChange">
      <n-button class="submitBn">上传文件</n-button>
    </n-upload>
    <div v-if="showError" style="position: absolute; top: 40px; left: 50%; width: 300px; margin-left: -150px; z-index: 100000">
      <n-alert :type="errorValue.type">
        {{ errorValue.value }}
      </n-alert>
    </div>
    <div v-if="showImgModal">
      <n-modal v-model:show="showImgModal" 
        preset="dialog"
        title="提示"
        content="当前有⼀张已上传图⽚，AI会继续根据该图⽚⽣成新图⽚，如果想忽略该图⽚的影响，请将该图⽚删除"
        positive-text="继续生成"
        negative-text="返回"
        @positive-click="onPositiveClick"
        @negative-click="onNegativeClick">
      </n-modal>
    </div>
    <!-- 当前有⼀张已上传图⽚，AI会继续根据该图⽚⽣成新图⽚，如果想忽略该图⽚的影响，请将该图⽚删除 -->
  </div>
</template>

<script>
//NImage
import { NDropdown, NTooltip, NInput, NCollapse, NCollapseItem, NSlider, NUpload, NButton, NAlert, NEllipsis, NPopconfirm, NModal } from "naive-ui";
import aboutItem from "./about.vue";
import loginModel from "./loginModel.vue";
import dialogueBox from "./dialogueBox.vue";
import request from "../../request/request";

import tdModel from "./styleImg/3d-model.png";
import analogFilm from "./styleImg/analog-film.png";
import anime from "./styleImg/anime.png";
import cinematic from "./styleImg/cinematic.png";
import comicBook from "./styleImg/comic-book.png";
import digitalArt from "./styleImg/digital-art.png";
import enhance from "./styleImg/enhance.png";
import fantasyArt from "./styleImg/fantasy-art.png";
import isometric from "./styleImg/isometric.png";
import lineArt from "./styleImg/line-art.png";
import lowPoly from "./styleImg/low-poly.png";
import modelingCompound from "./styleImg/modeling-compound.png";
import neonPunk from "./styleImg/neon-punk.png";
import origami from "./styleImg/origami.png";
import photographic from "./styleImg/photographic.png";
import pixelArt from "./styleImg/pixel-art.png";

export default {
  components: {
    NModal,
    aboutItem,
    dialogueBox,
    loginModel,
    NDropdown,
    NTooltip,
    NAlert,
    NEllipsis,
    NInput,
    NCollapse,
    NCollapseItem,
    NPopconfirm,
    NUpload,
    NButton,
    // NImage,
    NSlider,
    // NDatePicker
  },
  data() {
    return {
      // 打包注意
      // daBaoUrl: "api/",
      nowHost: "http://aigc.camluni.com:8000",
      // nowHost: location.origin,
      daBaoUrl:'',

      WidthSize: 380,
      OldWidthSize: 0,
      oldDownData: 0,
      isResize: false,

      HeightSize: 220,
      OldHeightSize: 0,
      oldHeightDownData: 0,
      isHeightResize: false,

      ShowAbout: false,
      showModal: false,
      isFirstImg: false,
      showImgModal: false,
      ShowSupport: false,
      showError: false,
      isTalkView: false, // 是否关闭最后一项的动画
      errorValue: {
        type: "success",
        value: "-----------",
      },
      hasLogin: false,
      sendType: "world",
      sendValue: "",
      effectData: 50,
      options: [
        // {
        //   label: "设置",
        //   key: "setting",
        // },
        {
          label: "退出",
          key: "logout",
        },
      ],
      nowCheckEdType: {
        typeName: "增强",
        typeId: "enhance",
        typeImg: enhance,
      },
      checkEdMarksArray: {
        0: [896, 512],
        12.5: [768, 512],
        25: [768, 576],
        37.5: [640, 512],
        50: [512, 512],
        62.5: [512, 640],
        75: [576,768],
        87.5: [512, 768],
        100: [512, 896],
      },
      checkEdMarks: 50,
      sliderMarks: {
        0: "7:4",
        12.5: "3:2",
        25: "4:3",
        37.5: "5:4",
        50: "1:1",
        62.5: "4:5",
        75: "3:4",
        87.5: "2:3",
        100: "4:7",
      },
      // historyTalkImgArry: {
      //   id:'00111',
      //   type:'imgTalk',
      //   records:[]
      // },
      historyTalkTitle: [],
      nowUserTalk: null,
      styleArray: [
        {
          typeName: "增强",
          typeId: "enhance",
          typeImg: enhance,
        },
        {
          typeName: "动漫",
          typeId: "anime",
          typeImg: anime,
        },
        {
          typeName: "摄影",
          typeId: "photographic",
          typeImg: photographic,
        },
        {
          typeName: "数码",
          typeId: "digital-art",
          typeImg: digitalArt,
        },
        {
          typeName: "漫画",
          typeId: "comic-book",
          typeImg: comicBook,
        },
        {
          typeName: "幻想",
          typeId: "fantasy-art",
          typeImg: fantasyArt,
        },
        {
          typeName: "胶片",
          typeId: "analog-film",
          typeImg: analogFilm,
        },
        {
          typeName: "霓虹",
          typeId: "neon-punk",
          typeImg: neonPunk,
        },
        {
          typeName: "等轴",
          typeId: "isometric",
          typeImg: isometric,
        },
        {
          typeName: "粘土",
          typeId: "modeling-compound",
          typeImg: modelingCompound,
        },
        {
          typeName: "低面",
          typeId: "low-poly",
          typeImg: lowPoly,
        },
        {
          typeName: "折纸",
          typeId: "origami",
          typeImg: origami,
        },
        {
          typeName: "线描",
          typeId: "line-art",
          typeImg: lineArt,
        },
        {
          typeName: "电影",
          typeId: "cinematic",
          typeImg: cinematic,
        },
        {
          typeName: "三维",
          typeId: "3d-model",
          typeImg: tdModel,
        },
        {
          typeName: "像素",
          typeId: "pixel-art",
          typeImg: pixelArt,
        },
      ],
      userInfo: {},
      valueArray: [],
      valueImgArray: [],
      valueImgUrl: [],
      DefaultInterVal: null,
      MyNewTimeOut: null,
      isShift:false,
      Base64Img: "",
      imgFileBox: "",
      imgOldFileBox: "",
      qrCodeUrl: "",
    };
  },
  mounted() {
    let userData = localStorage.getItem("userInfoByAICG");
    if (userData && userData !== "{}" && JSON.parse(userData).token) {
      this.userInfo = userData;
      this.hasLogin = true;
      this.GetUserValue();
    } else {
      this.userInfo = {};
      this.hasLogin = false;
    }
    request.get("/api/v1/common/wx/public/code").then((res) => {
      if (res.code === 200) {
        // "api/" +
        this.qrCodeUrl = res.data.img || "";
      }
    });
    this.getDefaultValue();

    document.onkeyup = (event) =>{
      var e = event || window.event;
      if (e && e.keyCode == 16){
        console.log(e)
        this.isShift = false
      }
    }

    document.onkeydown = (event) => {
      var e = event || window.event;
      if (e && e.keyCode == 16) {
        this.isShift = true
      }
          console.log(111111)
      if (e && e.keyCode == 13) {
        //回车键的键值为13
        if( this.showModal ){
          this.$refs.LoginModel.enterEvent()
        } else if(!this.isShift) {
          console.log(111111)
          var regu = "^[ ]+$";
          var re = new RegExp(regu);
          let isNull = this.sendValue === "" || re.test(this.sendValue);
          if (!isNull) {
            this.sendMessage();
          } else {
            this.showError = true;
            this.errorValue = {
              type: "info",
              value: "请输入需要回答的问题！",
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
          // this.sendSSEMessage()
          return false;
        }
      }
    };
  },
  methods: {
    moveHeightDown(event) {
      let that = this;
      this.oldHeightDownData = event.clientY;
      this.isHeightResize = true;
      this.OldHeightSize = this.HeightSize;
      window.onmousemove = (e) => {
        if (that.isHeightResize) {
          let heightChange = e.clientY - that.oldHeightDownData;
          let newWidth = that.OldHeightSize + heightChange;
          that.HeightSize = newWidth;
        }
        return false;
      };
      window.onmouseup = (ev) => {
        console.log(ev);
        that.isHeightResize = false;
        that.oldHeightDownData = 0;
      };
    },
    moveDown(event) {
      let that = this;
      this.oldDownData = event.clientX;
      this.isResize = true;
      this.OldWidthSize = this.WidthSize;
      window.onmousemove = (e) => {
        if (that.isResize) {
          let heightChange = e.clientX - that.oldDownData;
          let newWidth = that.OldWidthSize + heightChange;
          if (newWidth > 220) {
            that.WidthSize = newWidth;
          }
        }
        return false;
      };
      window.onmouseup = (ev) => {
        console.log(ev);
        that.isResize = false;
        that.oldDownData = 0;
      };
    },
    sendSSEMessage(data, ques, id) {
      let token = "";
      let userInfo = localStorage.getItem("userInfoByAICG");
      if (userInfo && userInfo !== "{}" && JSON.parse(userInfo).token) {
        token = JSON.parse(userInfo).token; // 设置请求头
      }
      let idValue = id === -1 ? "" : "chat_session_id=" + id +'&';
      let oldQue = ques.split('\n')
      let newQue = '';
      oldQue.forEach((ele,index) => {
        if(index!==0){
          newQue = newQue +'\\n'+ele
        }else{
          newQue = newQue + ele
        }
      });
      newQue = encodeURIComponent(newQue)
      fetch(this.nowHost + "/api/v1/chat/message/send?"+ idValue+"prompt=" + newQue , {
        headers: {
          token: token,
        },
      })
        .then((response) => {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let that = this;
          data.records[data.records.length - 1].updated_at = this.getNowTime();
          function processText({ done, value }) {
            that.chunk(2000);
            if (done) {
              that.isTalkView = false;
              console.log("Stream finished");
              return;
            }
            that.$refs.myDialogueBox.scrollToBottom();
            // console.log(decoder.decode(value))
            if (decoder.decode(value).includes("camlunistopdream")) {
              data.records[data.records.length - 1].recover = data.records[data.records.length - 1].recover + decoder.decode(value).replace("camlunistopdream", "");
              that.isTalkView = false;
            } else {
              data.records[data.records.length - 1].recover = data.records[data.records.length - 1].recover + decoder.decode(value);
            }
            return reader.read().then(processText);
          }
          return reader.read().then(processText);
        })
        .catch((error) => {
          console.log(error);
          this.isTalkView = false;
        });
    },
    chunk(timer) {
      if (this.MyNewTimeOut) clearTimeout(this.MyNewTimeOut);
      this.MyNewTimeOut = setTimeout(() => {
        this.isTalkView = false;
        this.MyNewTimeOut = null;
      }, timer);
    },
    GetUserValue() {
      this.GetAllTalk();
    },
    GetAllTalk() {
      // 获取聊天列表
      request.get("/api/v1/chat/session/").then((res) => {
        if (res.code === 200) {
          this.historyTalkTitle = res.data;
        }
      });
    },
    AddNewTalk() {
      // 新增聊天
      this.$refs.myMainBottom.scrollTop = this.$refs.myMainBottom.scrollHeight
      if (this.hasLogin) {
        request.post("/api/v1/chat/session/", { title: "新的聊天！" }).then((res) => {
          if (res.code === 200) {
            this.showError = true;
            request.get("/api/v1/chat/session/").then((res) => {
              if (res.code === 200) {
                this.historyTalkTitle = res.data;
                this.changeNowTalk(this.historyTalkTitle[0]);
              }
            });
            this.errorValue = {
              type: "success",
              value: "新增成功！",
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
        });
      } else {
        this.historyTalkTitle.unshift({
          title: "新的聊天！",
          id: -1,
        });
      }
    },
    changeNowTalk(item) {
      // 修改当前聊天对话
      if (item.id !== -1) {
        request.get(`/api/v1/chat/session/${item.id}/`).then((res) => {
          if (res.code === 200) {
            res.data.records.forEach(ele => {
              ele.prompt = ele.prompt.replaceAll('\\n','\n')
            });
            this.nowUserTalk = res.data;
          } else {
            this.showError = true;
            this.GetAllTalk();
            this.errorValue = {
              type: "error",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
        });
      } else {
        this.nowUserTalk = {
          id: -1,
          records: [],
          title: "新的聊天！",
        };
      }
    },
    deleteOneTalk(item) {
      // 删除聊天
      if (item.id !== -1) {
        request.delete(`/api/v1/chat/session/${item.id}/`).then((res) => {
          if (res.code === 200) {
            this.GetAllTalk();
            this.nowUserTalk = null;
            this.showError = true;
            this.errorValue = {
              type: "success",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          } else {
            this.showError = true;
            this.errorValue = {
              type: "error",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
        });
      }
    },
    getDefaultValue() {
      // setTimeout(() => {
      //   this.getDefaultValue()
      // }, 10000);
      request.get("/api/v1/chat/example?chat_type=text&amount=6").then((res) => {
        if (res.code === 200) {
          this.valueArray = res.data.chats;
        }
      });
      request.get("/api/v1/chat/example?chat_type=image&amount=6").then((res) => {
        if (res.code === 200) {
          this.valueImgArray = res.data.chats;
          let index = Math.floor(this.valueImgArray.length * Math.random())
          this.valueImgUrl = this.daBaoUrl +  this.valueImgArray[index].images[0]
        }
      });
    },
    beforeUpload(data) {
      if (data.file.file?.type !== "image/png" && data.file.file?.type !== "image/jpeg") {
        this.showError = true;
        this.errorValue = {
          type: "error",
          value: "只能上传png,jpg格式的图片文件，请重新上传",
        };
        setTimeout(() => {
          this.showError = false;
        }, 1000);
        return false;
      }
      return true;
    },
    changeSendType(type) {
      this.sendType = type;
      // if (type === "img") {
      //   this.nowUserTalk = this.historyTalkImgArry;
      // } else {
      //   this.nowUserTalk = null
      //   // this.nowUserTalk = this.historyTalkArray[0] || null;
      // }
    },
    handleNegativeClick(index) {
      console.log(index);
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    handleChange(file) {
      // this.imgOldFileBox = file.file.file;
      this.isFirstImg = true
      this.getBase64(file.file.file).then((res) => {
        this.imgOldFileBox = res;
        this.getImgFile()
      });
    },
    clearImg(){
      this.imgOldFileBox = ''
      this.imgFileBox = ''
      this.Base64Img = ''
    },
    getImgFile(){
      var that = this
      if(this.imgOldFileBox === ''){
        return
      }
      var canvas = document.createElement("canvas"); // 创建canvas对象
      var ctx = canvas.getContext('2d');
      var image = new Image();
        image.src = `${this.imgOldFileBox}`;
        image.onload = function () {
            // 获取原图宽高
            var width = that.checkEdMarksArray[that.checkEdMarks][0];
            var height = that.checkEdMarksArray[that.checkEdMarks][1];
            //设置canvas大小与原图宽高一致
            canvas.height = height;
            canvas.width = width;
            // 在canvas绘制图片
            ctx.drawImage(this, 0, 0, width, height);
            // 截图：
            // 获取截图区域内容,截图区域的像素点矩阵
            var cutImage = ctx.getImageData(0, 0, width, height);
            // 裁剪后的base64数据
            var newImage = that.createNewCanvas(cutImage, width, height);
            that.Base64Img = newImage;
            let blob = that.dataURLtoBlob(newImage)
            var file = that.blobToFile(blob, '999.png');		// blob -> file	
            that.imgFileBox = file
        }
    },
    createNewCanvas (content, width, height) {
        var nCanvas = document.createElement('canvas');
        var nCtx = nCanvas.getContext('2d');
        nCanvas.width = width;
        nCanvas.height = height;
        nCtx.putImageData(content, 0, 0); // 将画布上指定矩形的像素数据，通过 putImageData() 方法将图像数据放回画布
        return nCanvas.toDataURL('image/png');
    },
    dataURLtoBlob (dataurl) { 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    },
    blobToFile(theBlob, fileName){
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        let files = new window.File([theBlob], fileName, {type: 'image/png'})
        return files;
    },
    UploadClick() {
      document.querySelector(".submitBn")?.click();
    },
    submit(data) {
      if (data && data.data) {
        window.localStorage.setItem("userInfoByAICG", JSON.stringify(data.data));
        this.userInfo = data.data;
        this.nowUserTalk = null;
        this.hasLogin = true;
        this.GetUserValue();
      }
      this.showModal = false;
    },
    handleSelect(key) {
      if (key === "logout") {
        window.localStorage.setItem("userInfoByAICG", "{}");
        this.userInfo = {};
        this.hasLogin = false;
        this.showError = true;
        this.GetAllTalk();
        this.errorValue = {
          type: "success",
          value: "退出成功！",
        };
        this.historyTalkTitle = [];
        this.nowUserTalk = null;
        this.showModal = true;
        setTimeout(() => {
          this.showError = false;
        }, 1000);
      }
    },
    closeModal() {
      this.showModal = false;
    },
    loginFun() {
      this.showModal = true;
    },
    sendMessage() {
      if (this.nowUserTalk && this.nowUserTalk.id !== undefined) {
        if (this.nowUserTalk.records.length === 0 && this.nowUserTalk.id !== -1) {
          request.put(`/api/v1/chat/session/${this.nowUserTalk.id}/`, { title: this.sendValue }).then((res) => {
            if (res.code === 200) {
              this.GetAllTalk();
            }
          });
        }
        this.sendMessageYanZheng();
      } else {
        // 新增聊天
        if (!this.hasLogin) {
          this.historyTalkTitle.unshift({
            title: "新的聊天！",
            id: -1,
          });
          this.nowUserTalk = {
            id: -1,
            records: [],
            title: "新的聊天！",
          };
          setTimeout(() => {
            this.sendMessageYanZheng();
          }, 200);
        } else {
          request.post("/api/v1/chat/session/", { title: this.sendValue }).then((res1) => {
            if (res1.code === 200) {
              request.get("/api/v1/chat/session/").then((res2) => {
                if (res2.code === 200) {
                  this.historyTalkTitle = res2.data;
                  let item = res2.data[0];
                  request.get(`/api/v1/chat/session/${item.id}/`).then((res) => {
                    if (res.code === 200) {
                      res.data.records.forEach(ele => {
                        ele.prompt = ele.prompt.replaceAll('\\n','\n')
                      });
                      this.nowUserTalk = res.data;
                      setTimeout(() => {
                        this.sendMessageYanZheng();
                      }, 200);
                    }else{
                      this.showError = true;
                      this.errorValue = {
                        type: "info",
                        value: res.msg,
                      };
                      setTimeout(() => {
                        this.showError = false;
                      }, 1000);
                    }
                  });
                }else{
                  this.showError = true;
                  this.errorValue = {
                    type: "info",
                    value: res2.msg,
                  };
                  setTimeout(() => {
                    this.showError = false;
                  }, 1000);
                }
              });
            }else{
              this.showError = true;
              this.errorValue = {
                type: "info",
                value: res1.msg,
              };
              setTimeout(() => {
                this.showError = false;
              }, 1000);
            }
          });
        }
      }
    },
    onPositiveClick(){
      this.sendMessageQus()
      this.showImgModal = false
    },
    onNegativeClick(){
      this.showImgModal = false
    },
    sendMessageYanZheng(){
      if(this.sendType === "img" && this.imgFileBox!=='' && this.isFirstImg===false){
        this.showImgModal = true
      }else{
        this.sendMessageQus()
      }
    },
    sendMessageQus() {
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      let isNull = this.sendValue === "" || re.test(this.sendValue);
      if (this.isTalkView || isNull || this.nowUserTalk === null) {
        this.showError = true;
        this.errorValue = {
          type: "info",
          value: this.isTalkView ? "AI正在回答，请稍后" : isNull ? "请输入需要回答的问题！" : "请选择聊天后再发送问题！",
        };
        setTimeout(() => {
          this.showError = false;
        }, 1000);
      } else {
        if (!this.hasLogin) {
          let nowTime = new Date();
          let time = nowTime.getFullYear() + "/" + (nowTime.getMonth() + 1) + "/" + nowTime.getDate();
          let useTime = localStorage.getItem("useTimefoByAICG") * 1;
          let useDate = localStorage.getItem("useDatefoByAICG");
          if (useDate && useDate === time && useTime) {
            if (useTime < 10) {
              window.localStorage.setItem("useTimefoByAICG", useTime + 1);
            } else {
              this.showError = true;
              this.errorValue = {
                type: "info",
                value: "体验次数已耗尽，如需继续使用，请注册用户并登录后继续免费使用！",
              };
              this.showModal = true;
              setTimeout(() => {
                this.showError = false;
              }, 15000);
              return;
            }
          } else {
            window.localStorage.setItem("useDatefoByAICG", time);
            window.localStorage.setItem("useTimefoByAICG", 1);
          }
        }
        let askValue = this.sendValue;
        this.sendValue = "";
        this.$refs.myDialogueBox.scrollToBottom();
        if (this.sendType === "img") {
          this.isTalkView = true;
          let paramFromData = {
            chat_session_id: this.nowUserTalk.id,
            file: this.imgFileBox,
            samples: 2,
            width: this.checkEdMarksArray[this.checkEdMarks][0],
            height: this.checkEdMarksArray[this.checkEdMarks][1],
            prompt: askValue,
            style_preset: this.nowCheckEdType.typeId,
            image_strength: (this.effectData / 100).toFixed(1),
          };
          let timeId = this.getNowTime();
          this.nowUserTalk.records.push({
            created_at: timeId,
            prompt: askValue,
            showIndex: 0,
            type: "image",
            images: [],
            updated_at: "",
          });
          this.isFirstImg = false
          request.post("/api/v1/chat/image/create", paramFromData).then((res) => {
            this.isTalkView = false;
            if (res.code === 200) {
              this.nowUserTalk.records.forEach((ele) => {
                if (ele.created_at === timeId) {
                  ele.updated_at = this.getNowTime();
                }
              });
              let ImgArry = res.data;
              if (ImgArry.length > 0) {
                this.nowUserTalk.records.forEach((ele) => {
                  if (ele.created_at === timeId) {
                    ele.images = ImgArry;
                  }
                });
                ImgArry.forEach((imgEle, index) => {
                  setTimeout(() => {
                    this.nowUserTalk.records.forEach((ele) => {
                      if (ele.created_at === timeId) {
                        console.log(imgEle);
                        ele.showIndex = index;
                      }
                    });
                  }, index * 500);
                });
              }
            } else {
              this.showError = true;
              this.nowUserTalk.records.forEach((ele) => {
                if (ele.created_at === timeId) {
                  ele.updated_at = this.getNowTime();
                  ele.recover = [];
                  ele.recover.push([]);
                }
              });
              this.errorValue = {
                type: "error",
                value: res.msg,
              };
              setTimeout(() => {
                this.showError = false;
              }, 1000);
            }
          });
        } else {
          this.isTalkView = true;
          this.nowUserTalk.records.push({
            created_at: this.getNowTime(),
            prompt: askValue,
            type: "text",
            recover: "",
            updated_at: "",
          });
          this.sendSSEMessage(this.nowUserTalk, askValue, this.nowUserTalk.id);
        }
      }
    },
    getNowTime() {
      let nowTime = new Date();
      let time = nowTime.getFullYear() + "/" + (nowTime.getMonth() + 1) + "/" + nowTime.getDate() + " " + nowTime.getHours() + ":" + nowTime.getMinutes() + ":" + nowTime.getSeconds();
      return time;
    },
  },
  metaInfo: {
    title: "卡墨智能AIGC", // set a title
    meta: [
      {
        name: "keyWords",
        content: "title",
      },
      {
        name: "description",
        content: "卡墨智能 AIGC 创造无限可能",
      },
    ],
  },
};
</script>
<style>
.n-input .n-input__input-el, .n-input .n-input__textarea-el{
  color: white;
}
.n-popover {
  background-color: #444 !important;
  color: white;
}
.n-popover-shared .n-popover-arrow-wrapper .n-popover-arrow {
  background-color: #444;
}
.n-popover .n-button {
  color: white;
}
.n-collapse .n-collapse-item {
  border: none !important;
  margin: 0;
  color: #bebebe;
  background-color: transparent;
  margin-bottom: 10px;
  overflow: hidden;
}
.mainBody {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
}
.n-collapse .n-collapse-item .n-collapse-item__header {
  padding: 10px 15px !important;
  background-color: #232323;
  border-radius: 10px;
}
.n-collapse .n-collapse-item .n-collapse-item__content-wrapper .n-collapse-item__content-inner {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #2f2f2f;
}
.n-collapse .n-collapse-item .n-collapse-item__header .n-collapse-item__header-main {
  justify-content: space-between;
  color: white;
  flex-wrap: wrap;
}
.n-collapse .n-collapse-item .n-collapse-item__header .n-collapse-item-arrow {
  color: white;
}
.n-slider .n-slider-rail .n-slider-rail__fill {
  background-color: transparent !important;
}
.n-slider .n-slider-dots .n-slider-dot.n-slider-dot--active {
  border: 2px solid rgb(219, 219, 223);
}
.mainTop {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  background-color: #161616;
  border-bottom: 1px solid #444444;
}
.mainTop .imgTitle {
  height: 100%;
  width: 30%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainTop .imgTitle img {
  height: 30%;
}
@media (min-width: 0px) and (max-width: 600px) {
  .mainBottom {
    flex-direction: column !important;
  }
  .mainBottom .resizeDrop {
    display: none;
  }
  .mainBottom .resizeHeightDrop {
    display: none;
  }
  .mainBottom .mainLeft {
    width: 100% !important;
  }
  .mainBottom .mainRight {
    width: 100% !important;
  }
  .dialogueBox {
    flex: auto !important;
    height: 400px !important;
  }
  .imgBoxSetting {
    height: 350px !important;
    flex: auto !important;
  }
  .mainTop .userTitle .support .supportBox {
    right: -120px !important;
  }
  .mainTop .userTitle .support .smallSanJ {
    right: 130px !important;
  }
  .mainTop .userTitle .support .smallCom {
    right: 125px !important;
  }
  .mainTop .userTitle .aboutBox {
    right: -80px !important;
  }
  .mainTop .userTitle .aboutBox .smallSanJ {
    right: 90px !important;
  }
  .mainTop .userTitle .aboutBox .smallCom {
    right: 85px !important;
  }
}

.mainTop .userTitle {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}
.mainTop .userTitle .support {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
  background-image: url(./imgs/025.png);
  background-size: 100% 100%;
  position: relative;
}
.mainTop .userTitle .support .supportBox {
  position: absolute;
  height: 450px;
  width: 350px;
  top: 40px;
  border-radius: 8px;
  box-shadow: 0 0 3px 1px #afafaf;
  right: -10px;
  z-index: 10;
  background-color: #232323;
}
.supportBox .smallSanJ {
  height: 10px;
  width: 10px;
  background-color: #232323;
  box-shadow: 0 0 3px 1px #afafaf;
  position: absolute;
  top: -4px;
  right: 20px;
  transform: rotateZ(45deg);
}
.supportBox .smallCom {
  height: 20px;
  width: 20px;
  background-color: #232323;
  top: 0;
  right: 15px;
  position: absolute;
}
.supportBox .supportData {
  height: 100%;
  width: 100%;
  padding: 20px;
}
.supportBox .supportData .valueData {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.supportBox .supportData .valueData span {
  width: 100%;
  height: 30px;
  color: white;
  font-size: 15px;
  text-align: center;
}
.supportBox .supportData .supportImg {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.supportBox .supportData .supportImg img {
  height: 100%;
}
.mainTop .userTitle .about {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-right: 10px;
  background-image: url(./imgs/024.png);
  background-size: 100% 100%;
  position: relative;
}
.mainTop .userTitle .aboutBox {
  position: absolute;
  height: 90vh;
  width: 80vw;
  top: 40px;
  cursor: auto;
  border-radius: 8px;
  box-shadow: 0 0 3px 1px #afafaf;
  right: -10px;
  background-color: #1c1c1c;
  z-index: 10;
}
.aboutBox .smallSanJ {
  height: 10px;
  width: 10px;
  background-color: #1c1c1c;
  box-shadow: 0 0 3px 1px #afafaf;
  position: absolute;
  top: -4px;
  right: 20px;
  transform: rotateZ(45deg);
}
.aboutBox .smallCom {
  height: 20px;
  width: 20px;
  background-color: #1c1c1c;
  top: 0;
  right: 15px;
  position: absolute;
}
.mainTop .userTitle .userInfo {
  width: 80px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(153, 113, 38);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainTop .userTitle .userImg {
  height: 42px;
  width: 42px;
}
.mainTop .userTitle .userImg img {
  height: 100%;
  width: 100%;
}

.mainBottom {
  width: 100%;
  flex: 1;
  display: flex;
  overflow: auto;
}

.mainBottom .resizeDrop {
  width: 4px;
  height: 100%;
  position: absolute;
  right: -2px;
  background-color: #3a3a3c;
  bottom: 0;
  cursor: e-resize;
}
.mainBottom .resizeHeightDrop {
  height: 4px;
  width: 100%;
  background-color: #3a3a3c;
  cursor: n-resize;
}
.mainBottom .mainLeft {
  width: 25%;
  height: 100%;
  background-color: #161616;
  border-right: 1px solid #444444;
  display: flex;
  flex-direction: column;
}
.mainBottom .mainLeft .replyView {
  width: 100%;
  max-height: 350px;
  min-height: 200px;
  padding: 10px 20px;
  border-bottom: 1px solid #444444;
  overflow: auto;
}
.defaultQue .defaultTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 4%;
}
.defaultQue .TitleValue {
  background-color: #5b5b5b;
  border-radius: 5px;
  height: 42px;
  width: 255px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.defaultImgQue {
  width: 100%;
  max-height: 350px;
  padding: 10px 20px;
  border-bottom: 1px solid #444444;
  overflow: auto;
}
.defaultImgQue .defaultTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 4%;
}
.defaultImgQue .TitleValue {
  background-color: #5b5b5b;
  border-radius: 5px;
  height: 42px;
  width: 255px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.defaultValue {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.defaultValue .valueBox {
  width: 45%;
  background-color: #232323;
  border-radius: 8px;
  padding: 10px 13px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
.mainBottom .mainLeft .replyBox {
  width: 100%;
  flex: 1;
  overflow: auto;
  padding: 0 10px 0 10px;
}
.replyBox .AddNewTalk {
  width: 100%;
  height: 60px;
  background: #161616;
  border: 1px dashed #bebebe;
  border-radius: 8px;
  color: #999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.mainBottom .mainLeft .wxCode {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 14px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
}
.mainBottom .mainLeft img {
  height: 80%;
  max-width: 100px;
}
.mainBottom .mainRight {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
}
.mainBottom .mainRight .dialogueBox {
  flex: 1;
  width: 100%;
  position: relative;
  overflow: auto;
}
.mainBottom .mainRight .askViewBox {
  width: 100%;
  height: 90px;
  background-color: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgBoxSetting .Checked {
  border-color: white !important;
}
.imgBoxSetting .checkedStyle {
  opacity: 0.9 !important;
}
.imgBoxSetting .styleChild {
  cursor: pointer;
  border-radius: 5px;
}
.imgBoxSetting .styleChild:hover {
  border: 1px dashed white;
}
.HistoryItemChecked {
  background-color: #595a5b !important;
}
</style>
