import axios from 'axios'
 

// 打包需要改一下
const request = axios.create({
  // baseURL: '/api', //使用/api作为基地址
  baseURL: '', //使用/api作为基地址
  timeout: 100000
})
 
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
  if(config.url === '/api/v1/chat/image/create'){
    config.headers['Content-Type'] = 'multipart/form-data';
  }else{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
  }

  let userInfo =  localStorage.getItem('userInfoByAICG')
  if(userInfo && userInfo!=='{}' && JSON.parse(userInfo).token){
    config.headers['token'] = JSON.parse(userInfo).token;  // 设置请求头
  }

  return config
}, error => {
  return Promise.reject(error)
});
 
// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  response => {
    let res = response.data;
    // 如果是返回的文件
    if (response.config.responseType === 'blob') {
      return res
    }
    // 兼容服务端返回的字符串数据
    // if (typeof res === 'string') {
    //   res = res ? JSON.parse(res) : res
    // }
    return res;
  },
  error => {
    console.log('err' + error) // for debug
    let data = error.response ? error.response.data : '接口错误'
    return Promise.reject(data)
  }
)
 
export default request;
 