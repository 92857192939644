<template>
  <div class="aboutData" ref="myAboutData">
    <div class="aboutTop">
      <div class="leftBox">
        <div class="lettValue1">卡墨智能 AIGC </div>
        <div class="lettValue1">创造无限可能</div>
        <div class="lettValue2">连接创作者、设计师和开发者的AI新世界</div>
        <div class="lettValue3">
          <div  @click="StartUse">开始使用</div>
        </div>
        <div class="lettValue4">
          <div>智能助理</div>
          <div>图片生成</div>
          <div>翻译纠错</div>
          <div>代码生成</div>
        </div>
      </div>
      <div class="rightBox">
        <!-- <img src="./imgs/002.png" alt="" /> -->
        <img :src="valueImgUrl" alt="" />
      </div>
    </div>
    <!-- <div class="aboutBottom">
      <div class="aboutValue">
        <div class="valueItem">面试想拿10K,HR说你只值7K,该怎样回答或者反驳？</div>
        <div class="valueItem">python是什么？</div>
        <div class="valueItem">我想要一张城市图片，人工智能风格。</div>
        <div class="valueItem">简历上的实习经历该如何去写？</div>
        <div class="valueItem">人工智能</div>
        <div class="valueItem">可爱的、卡通的、双胞胎小老虎照片。</div>
        <div class="valueItem">2023年创业的方向有哪些可以选择？</div>
        <div class="valueItem">我在编程时给变量命名总觉得困难，有没有什么实用技巧？</div>
        <div class="valueItem">照片</div>
      </div>
    </div> -->
    <div class="aboutUs">
      <div class="aboutName">关于我们</div>
      <div class="valueBox">
        <div class="dataValue1">欢迎来到我们的平台，这里是一个提供与chatGPT和Midjourney相媲美，并且免费开放的AI内容创作社区！</div>
        <div class="dataValue1">
          本平台专为生成式AI学习、体验、实验和交流而生。由计算机科学教育者，以及AI独立开发者联合发起。不论你是寻求灵感的内容工作者，还是追求视觉突破的图片设计师，甚至是热衷于技术创新的开发者，我们真诚地邀请你加入我们的开放平台，一起探索AI创作的新边界。并将AI为生活和工作带来更多的可能性。
        </div>
      </div>
    </div>
    <div class="howToUse">
      <div class="useName">开始使用</div>
      <div class="useValue">
        <div class="useImg">
          <img src="./imgs/003.png" alt="" />
        </div>
        <div class="useData">
          <div class="dataArray">
            <div>直接在聊天提示区输入文字提示即可与AI对话</div>
            <div>使用 
              <img src="./imgs/010.png" style="height: 20px;width: 20px;" alt="">
              ，
              <img src="./imgs/009.png" style="height: 20px;width: 20px;" alt="">
              进行文字内容生成与图片内容切换</div>
            <div>无需注册也可使用（有生成次数限制）</div>
            <div>注册用户后可继续免费使用</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    valueImgUrl: String,
  },
  methods: {
    StartUse(){
      this.$refs.myAboutData.scrollTop = this.$refs.myAboutData.scrollHeight
    }
  },
}
</script>

<style scoped>
.aboutData {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: absolute;
}
.aboutData .aboutTop {
  width: 100%;
  height: 350px;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #444444;
}
.aboutData .aboutTop .leftBox {
  height: 100%;
  width: 65%;
  padding-left: 15%;
  color: white;
  padding-top: 2%;
}
.aboutData .aboutTop .leftBox .lettValue1 {
  font-size: 38px;
  color: #ffffff;
  width: 100%;
  height: 60px;
  font-weight: bold;
}
.aboutData .aboutTop .leftBox .lettValue2 {
  margin: 10px 0;
  font-size: 21px;
  color: white;
  width: 100%;
}
.aboutData .aboutTop .leftBox .lettValue3 {
  width: 100%;
  height: 42px;
  margin: 10px 0;
}
.aboutData .aboutTop .leftBox .lettValue3 div {
  height: 42px;
  width: 120px;
  background-color: #c2aa66;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutData .aboutTop .leftBox .lettValue4 {
  width: 100%;
  display: flex;
  height: 42px;
}
.aboutData .aboutTop .leftBox .lettValue4 div {
  width: 100px;
  height: 32px;
  border-radius: 10px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.aboutData .aboutTop .rightBox {
  height: 100%;
  width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.aboutData .aboutTop .rightBox img {
  width: 206px;
  height: 181px;
}
.aboutData .aboutBottom {
  width: 100%;
  height: 420px;
  padding: 20px;
}
.aboutData .aboutBottom .aboutValue {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  justify-content: center;
  align-items: center;
}
.aboutData .aboutBottom .aboutValue .valueItem {
  width: 25%;
  padding: 10px 14px;
  background-color: #232323;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  margin: 0 20px;
}
.aboutData .aboutUs .aboutName {
  width: 100%;
  height: 80px;
  font-size: 24px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutData .aboutUs .valueBox {
  width: 100%;
  color: #ffffff;
  font-size: 15px;
}
.aboutData .aboutUs .valueBox .dataValue1 {
  width: 100%;
  padding: 0 20% 60px 20%;
}
.aboutData .howToUse .useName {
  width: 100%;
  height: 80px;
  font-size: 24px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutData .howToUse .useValue {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.aboutData .howToUse .useValue .useImg {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.aboutData .howToUse .useValue .useImg img {
  width: 206px;
  height: 181px;
}
.aboutData .howToUse .useValue .useData {
  width: 50%;
  height: 100%;
  display: flex;
  color: white;
  padding-left: 10%;
  font-size: 15px;
  justify-content: flex-start;
  align-items: center;
}
.aboutData .howToUse .useValue .useData .dataArray {
  width: 330px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
}
.aboutData .howToUse .useValue .useData .dataArray div {
  width: 100%;
  position: relative;
}
.aboutData .howToUse .useValue .useData .dataArray div::before {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background-color: #fff;
  position: absolute;
  left: -10px;
  top: 10px;
  border-radius: 50%;
}
</style>
