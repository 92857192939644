<template>
  <n-modal v-model:show="LoginModal" :mask-closable="false">
    <n-card size="huge" :bordered="false" role="dialog" aria-modal="true">
      <div v-if="isWxLogin" class="ModelBox">
        <div class="wxLoginModel">
          <div class="BackBtn" @click="isWxLogin = false">
            <img src="./imgs/006.png" alt="" />
          </div>
          <div class="loginBox">
            <div class="hello flexCenter" style="color: white; font-size: 18px; padding-bottom: 32px">欢迎来到卡墨智能CHATBOT</div>
            <div class="checkType flexCenter" style="padding-bottom: 20px">
              <div class="checkBox checked">微信扫码登录</div>
            </div>
            <div class="flexCenter" style="width: 100%; height: 200px">
              <div class="erWeiMa flexCenter" style="width: 150px; height: 150px; border: 1px solid white; padding: 4px">
                <img style="width: 100%; height: 100%" src="./imgs/007.png" alt="" />
              </div>
            </div>
            <div class="footBox flexCenter" style="color: #666666; font-size: 14px">使用微信扫一扫登录</div>
          </div>
        </div>
      </div>
      <div v-else class="ModelBox">
        <div class="loginModel" v-if="isLogin">
          <div style="width: 100%; height: 100%" v-if="!forGetPassword">
            <div class="closeBtn" @click="closeModal">
              <img src="./imgs/004.png" alt="" />
            </div>
            <!-- 微信登录，暂时注释 -->
            <!-- <div class="wxLoginBtn">
              <img src="./imgs/005.png" alt="" @click="isWxLogin = true" />
            </div> -->
            <div class="loginBox">
              <div class="hello flexCenter" style="color: white; font-size: 18px; padding-bottom: 32px">欢迎来到卡墨智能CHATBOT</div>
              <div class="checkType flexCenter" style="padding-bottom: 20px">
                <div :class="submitType === '1' ? 'checkBox checked' : 'checkBox'" @click="submitType = '1'">密码登录</div>
                <div :class="submitType === '2' ? 'checkBox checked' : 'checkBox'" @click="submitType = '2'">手机登录</div>
              </div>
              <div class="loginFrom">
                <div class="name" style="padding-bottom: 10px">
                  <n-input style="width: 320px; height: 48px" v-model:value="loginFrom.telephone" type="text" placeholder="手机号" />
                </div>
                <div class="password" style="padding-bottom: 10px" v-if="submitType === '1'">
                  <n-input style="width: 320px; height: 48px" v-model:value="loginFrom.password" type="password" placeholder="密码" />
                </div>
                <div class="code" style="padding-bottom: 10px; display: flex; justify-content: space-around" v-if="submitType === '2'">
                  <n-input style="width: 190px; height: 48px" v-model:value="loginFrom.smscode" type="text" placeholder="验证码" />
                  <div class="flexCenter" @click="GetYzn(1)" style="width: 120px; height: 48px; color: #ffffff; font-size: 4px; cursor: pointer; background-color: #c2aa66; border-radius: 4px">
                    <n-countdown v-if="active" :render="renderCountdown" :duration="duration" :active="active" @finish="finishCountdown"/>
                    <span v-else> 获取验证码 </span>
                  </div>
                </div>
                <div class="flexCenter" style="height: 48px; width: 320px; background-color: #c2aa66; cursor: pointer; border-radius: 4px;color: white;" @click="LoginClick">登录</div>
                <div class="forget" style="height: 48px; width: 320px; text-align: right; cursor: pointer" @click="forGetPassword = true">忘记密码</div>
              </div>
              <div class="register flexCenter" style="color: #666666; font-size: 14px">
                还没有账号？
                <div style="color: #ffffff; font-size: 16px; margin-top: -2px; cursor: pointer" @click="isLogin = false">去注册>></div>
              </div>
            </div>
          </div>
          <div class="forgetBox" style="width: 100%; height: 100%" v-else>
            <div class="BackBtn" @click="forGetPassword = false">
              <img src="./imgs/006.png" alt="" />
            </div>
            <div class="hello flexCenter" style="color: white; font-size: 18px; padding-bottom: 32px">欢迎来到卡墨智能CHATBOT</div>
            <div class="checkType flexCenter" style="padding-bottom: 20px">
              <div class="checkBox checked">找回密码</div>
            </div>
            <div class="forgetFrom">
              <div class="name" style="padding-bottom: 10px">
                <n-input style="width: 320px; height: 48px" v-model:value="forgetFrom.telephone" type="text" placeholder="手机号" />
              </div>
              <div class="code" style="padding-bottom: 10px; display: flex; justify-content: space-around">
                <n-input style="width: 190px; height: 48px" v-model:value="forgetFrom.smscode" type="text" placeholder="验证码" />
                <div class="flexCenter" @click="GetYzn(2)" style="width: 120px; cursor: pointer; height: 48px; color: #ffffff; font-size: 4px; background-color: #c2aa66; border-radius: 4px">
                  <n-countdown v-if="active" :render="renderCountdown" :duration="duration" :active="active"  @finish="finishCountdown"/>
                  <span v-else> 获取验证码 </span>
                </div>
              </div>
              <div class="password" style="padding-bottom: 10px">
                <n-input style="width: 320px; height: 48px" v-model:value="forgetFrom.password" type="password" placeholder="新密码" />
              </div>
              <div class="flexCenter" style="height: 48px; width: 320px; background-color: #c2aa66; border-radius: 4px" @click="onPositiveClick">确定</div>
            </div>
          </div>
        </div>
        <div class="registerModel" v-else>
          <div class="closeBtn" @click="closeModal">
            <img src="./imgs/004.png" alt="" />
          </div>
          <div class="hello flexCenter" style="color: white; font-size: 18px; padding-bottom: 20px">欢迎来到卡墨智能CHATBOT</div>
          <div class="checkType flexCenter" style="padding-bottom: 20px">
            <div class="checkBox checked">注册</div>
          </div>
          <div class="registItem">
            <div class="name">用户名</div>
            <div class="value">
              <n-input style="width: 310px; height: 48px" v-model:value="registFrom.username" type="text" placeholder="2-6位" />
            </div>
          </div>
          <div class="registItem">
            <div class="name">手机号</div>
            <div class="value">
              <n-input style="width: 310px; height: 48px" v-model:value="registFrom.telephone" type="text" placeholder="请输入手机号" />
            </div>
          </div>
          <div class="registItem">
            <div class="name"></div>
            <div class="value" style="display: flex; justify-content: space-around">
              <n-input style="width: 180px; height: 48px; margin-right: 10px" v-model:value="registFrom.smscode" type="text" placeholder="请输入验证码" />
              <div class="flexCenter" @click="GetYzn(3)" style="width: 120px;cursor: pointer; height: 48px; color: #ffffff; font-size: 4px; background-color: #c2aa66; border-radius: 4px">
                <n-countdown v-if="active" :render="renderCountdown" :duration="duration" :active="active"  @finish="finishCountdown"/>
                <span v-else> 获取验证码 </span>
              </div>
            </div>
          </div>
          <div class="registItem">
            <div class="name">密码</div>
            <div class="value">
              <n-input style="width: 310px; height: 48px" v-model:value="registFrom.password" type="password" placeholder="6-12位" />
            </div>
          </div>
          <div class="registItem">
            <div class="name">邮箱</div>
            <div class="value">
              <n-input style="width: 310px; height: 48px" v-model:value="registFrom.email" type="text" placeholder="请输入邮箱" />
            </div>
          </div>
          <div class="registItem">
            <div class="name">所处行业</div>
            <div class="value">
              <n-input style="width: 310px; height: 48px" v-model:value="registFrom.industry" type="text" placeholder="所从事的行业" />
            </div>
          </div>
          <div class="registItem">
            <div class="name">注册用途</div>
            <div class="value">
              <n-input style="width: 310px; height: 48px" v-model:value="registFrom.purpose" type="text" placeholder="您使用AIGC的用途是什么？" />
            </div>
          </div>
          <div class="flexCenter" style="height: 48px; width: 310px; margin-left: 70px; background-color: #c2aa66; cursor: pointer; border-radius: 4px" @click="registerClick">注册</div>
          <div class="register flexCenter" style="color: #666666; font-size: 14px; margin-top: 14px">
            已有账号？
            <div style="color: #ffffff; font-size: 16px; margin-top: -2px; cursor: pointer" @click="isLogin = true">去登录>></div>
          </div>
        </div>
      </div>
    </n-card>
  </n-modal>
  <div v-if="showError" style="position: fixed; top: 40px; left: 50%; width: 300px; margin-left: -150px; z-index: 100000">
    <n-alert :type="errorValue.type">
      {{ errorValue.value }}
    </n-alert>
  </div>
</template>

<script>
import { NModal, NCard, NInput, NAlert, NCountdown } from "naive-ui";
import request from "../../request/request";
export default {
  name: "name",
  components: {
    NModal,
    NCard,
    NInput,
    NAlert,
    NCountdown,
  },
  props: {
    showModal: Boolean,
  },
  watch: {
    showModal() {
      this.LoginModal = this.showModal;
      if (this.LoginModal) {
        (this.isLogin = true), (this.forGetPassword = false);
        this.isWxLogin = false;
        this.submitType = "1";
      }
    },
  },
  data() {
    return {
      LoginModal: false,
      forGetPassword: false,
      isWxLogin: false,
      showError: false,
      errorValue: {
        type: "success",
        value: "-----------",
      },
      submitType: "1",
      isLogin: true,
      loginFrom: {
        telephone: "",
        password: "",
        smscode: "",
      },
      forgetFrom: {
        telephone: "",
        password: "",
        smscode: "",
      },
      registFrom: {
        telephone: "",
        username: "",
        password: "",
        email: "",
        industry: "",
        purpose: "",
        smscode: "",
      },
      active: false,
      duration: 59 * 1000,
    };
  },
  methods: {
    enterEvent(){
      if(!this.isWxLogin){
        if(this.isLogin){
          if(this.forGetPassword){
            this.onPositiveClick()
          }else{
            this.LoginClick()
          }
        }else{
          this.registerClick()
        }
      }
    },
    renderCountdown(data) {
      return `${String(data.seconds).padStart(2, "0")}`;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    finishCountdown(){
      this.active = false;
    },
    GetYzn(type) {
      if (this.active) {
        this.showError = true;
        this.errorValue = {
          type: "info",
          value: "短信已发送，请注意查收。",
        };
        setTimeout(() => {
          this.showError = false;
        }, 1000);
      } else {
        let typeArray = ['','login','findPassword','register']
        let telephone = ''
        if(type === 1){
          telephone = this.loginFrom.telephone
        }else if(type === 2){
          telephone = this.forgetFrom.telephone
        }else if(type === 3){
          telephone = this.registFrom.telephone
        }
        let from = {
          usage:typeArray[type],
          telephone:telephone
        }
        request.post('/api/v1/common/send/smscode', from).then((res) => {
          if(res.code !==200){
            this.showError = true;
            this.errorValue = {
              type: "error",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }else{
            this.duration = 59 * 1000;
            this.active = true;
            this.showError = true;
            this.errorValue = {
              type: "success",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
        })
      }
    },
    LoginClick() {
      let from = {};
      let api = "";
      if (this.submitType === "1") {
        api = "/api/v1/user/login/password";
        if(this.loginFrom.telephone ==='' || this.loginFrom.password === ''){
          this.showError = true;
          this.errorValue = {
            type: "info",
            value: "登录信息不完整！",
          };
          setTimeout(() => {
            this.showError = false;
          }, 1000);
        }
        from = {
          telephone: this.loginFrom.telephone,
          password: this.loginFrom.password,
        };
      } else if (this.submitType === "2") {
        api = "/api/v1/user/login/smscode";
        if(this.loginFrom.telephone ==='' || this.loginFrom.smscode === ''){
          this.showError = true;
          this.errorValue = {
            type: "info",
            value: "登录信息不完整！",
          };
          setTimeout(() => {
            this.showError = false;
          }, 1000);
        }
        from = {
          telephone: this.loginFrom.telephone,
          smscode: this.loginFrom.smscode,
        };
      }
      request.post(api, from).then((res) => {
        if (res.code !== 200) {
          this.showError = true;
          this.errorValue = {
            type: "error",
            value: res.msg,
          };
          setTimeout(() => {
            this.showError = false;
          }, 1000);
        } else {
          this.showError = true;
          this.errorValue = {
            type: "success",
            value: "登录成功",
          };
          this.$emit("submit", res);
          setTimeout(() => {
            this.showError = false;
          }, 1000);
          this.isLogin = true;
        }
      });
    },
    registerClick() {
      for (const key in this.registFrom) {
        if (Object.hasOwnProperty.call(this.registFrom, key)) {
          const element = this.registFrom[key];
          if(element === ''){
            this.showError = true;
            this.errorValue = {
              type: "info",
              value: "请完成注册信息填报！",
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
            return
          }
        }
      }
      request
        .post("/api/v1/user/register", this.registFrom)
        .then((res) => {
          if (res.code !== 200) {
            this.showError = true;
            this.errorValue = {
              type: "error",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          } else {
            this.showError = true;
            this.errorValue = {
              type: "success",
              value: "注册成功！",
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
            this.isLogin = true;
          }
        })
        .catch((err) => {
          if (err.code !== 200) {
            this.showError = true;
            this.errorValue = {
              type: "error",
              value: err.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
        });
    },
    onPositiveClick() {
      for (const key in this.forgetFrom) {
        if (Object.hasOwnProperty.call(this.forgetFrom, key)) {
          const element = this.forgetFrom[key];
          if(element === ''){
            this.showError = true;
            this.errorValue = {
              type: "info",
              value: "请完成找回信息填报！",
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
            return
          }
        }
      }
      request
        .post("/api/v1/user/password/change_by_smscode", this.forgetFrom)
        .then((res) => {
          if (res.code !== 200) {
            this.showError = true;
            this.errorValue = {
              type: "error",
              value: res.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }else{
            this.showError = true;
            this.errorValue = {
              type: "success",
              value: "修改成功",
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
            this.forGetPassword = false
          }
        }).catch((err)=>{
          if (err.code !== 200) {
            this.showError = true;
            this.errorValue = {
              type: "error",
              value: err.msg,
            };
            setTimeout(() => {
              this.showError = false;
            }, 1000);
          }
        })
      // forgetFrom
      console.log("修改密码");
      this.$emit("submit", "1111111");
    },
    onNegativeClick() {
      this.$emit("submit", "");
    },
  },
};
</script>
<style>
@media (min-width: 0px) and (max-width: 600px) {
  .ModelBox {
    transform: scale(0.7);
    margin-left: -85px;
  }
  .ModelBox {
    font-size: 24px !important;
  }
  .lettValue1 {
    font-size: 24px !important;
  }
  .lettValue2 {
    font-size: 14px !important;
  }
  .lettValue4 {
    flex-wrap: wrap;
  }
  .aboutBottom {
    height: auto !important;
  }
  .lettValue4 div {
    width: 50% !important;
    margin-right: 0 !important;
  }
  .valueItem {
    margin: 10px 20px !important;
    width: 80% !important;
  }
  .aboutData .howToUse .useValue .useImg img {
    width: 100% !important;
    height: auto !important;
  }
  .aboutData .aboutTop .rightBox img {
    width: 100% !important;
    height: auto !important;
  }
}

.n-card.n-modal {
  background-color: transparent;
  width: auto;
}
.n-card__content {
  padding: 0;
}
.n-card > .n-card__content:first-child {
  padding: 0;
}
.n-input {
  background-color: #232323 !important;
  border-radius: 4px;
  border: none !important;
}
.n-input .n-input__border {
  border: none !important;
}
.n-input .n-input__state-border {
  border: rgb(119, 118, 118) !important;
  box-shadow: none;
}
.n-input .n-input__input-el {
  color: white;
  height: 100%;
}
</style>
<style scoped>
.wxLoginModel {
  width: 580px;
  height: 400px;
  background-color: #161616;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.BackBtn {
  height: 16px;
  width: 10px;
  position: absolute;
  top: 20px;
  left: 30px;
  cursor: pointer;
}
.BackBtn img {
  height: 100%;
  width: 100%;
}
</style>

<style scoped>
.loginModel {
  width: 580px;
  height: 400px;
  background-color: #161616;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.loginModel .forgetBox {
  width: 100%;
  height: 100%;
  padding: 42px 130px 32px 130px;
}
.closeBtn {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 20px;
  left: 30px;
  cursor: pointer;
}
.closeBtn img {
  height: 100%;
  width: 100%;
}
.wxLoginBtn {
  height: 0;
  width: 0;
  border: 50px solid;
  border-color: #28251d #28251d transparent transparent;
  position: absolute;
  right: 0;
  top: 0;
}
.wxLoginBtn img {
  width: 34px;
  height: 28px;
  position: absolute;
  top: -30px;
  right: -36px;
  cursor: pointer;
}
.loginBox {
  width: 100%;
  height: 100%;
  padding: 42px 130px 32px 130px;
}
.checkType .checkBox {
  cursor: pointer;
  margin: 0 10px;
  position: relative;
}
.checkType {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checked {
  color: #c2aa66;
}
.checked::before {
  content: "";
  display: block;
  width: 2px;
  height: 14px;
  background-color: #c2aa66;
  position: absolute;
  left: -8px;
  top: 4px;
}
</style>
<style scoped>
.registerModel {
  width: 580px;
  height: 600px;
  padding: 42px 100px 32px 100px;
  background-color: #161616;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.registerModel .registItem {
  display: flex;
  height: 48px;
  margin: 0 0 5px 0;
}
.registerModel .registItem .name {
  width: 150px;
  height: 100%;
  padding-right: 10px;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.registerModel .registItem .value {
  flex: 1;
}
</style>
