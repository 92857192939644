<template>
    <div class="dialogueTalk" ref="dialogueTalk">
        <div class="MsgBox" v-for="(item,index) in nowUserTalk.records" :key="index">
            <div class="userShowView">
                <div class="infoPik">
                    <div class="vlaueTime">
                        {{ item.created_at }}
                    </div>
                    <div class="userValue">
                        <div style="text-align: left;">
                            <template v-for="(ele,ind) in item.prompt.split('\n')" :key="ind">
                                <br v-if="ind!==0"/>
                                {{ ele }}
                            </template>
                        </div>
                        <div class="smallSanjiao"></div>
                    </div>
                </div>
                <div class="imgPick">
                    <div style="border-radius: 50%;height: 42px;width: 42px;">
                        <img src="./imgs/008.png" style="width: 100%;height: 100%;" alt="" />
                    </div>
                </div>
            </div>
            <div class="systemShowView">
                <div class="imgPick">
                    <div style="border-radius: 50%;height: 42px;width: 42px;">
                        <img src="./imgs/012.png" style="width: 100%;height: 100%;" alt="" v-if="item.type ==='image'">
                        <img src="./imgs/013.png" style="width: 100%;height: 100%;" alt="" v-else>
                    </div>
                </div>
                <div class="infoPik">
                    <div class="vlaueTime" style="height: 23px;">
                        {{ item.updated_at }}
                    </div>
                    <div class="userValue">
                        <div>
                            <div v-if="item.type ==='image'" style="display: flex;flex-wrap: wrap;">
                                <template 
                                    v-if="typeof(item.images)!=='string' && item.images.length>0">
                                    <div class="ImgBox"
                                        v-for="(d,index) in item.images"
                                        :key="index">
                                        <n-image
                                            :class="item.showIndex==undefined || item.showIndex>=index?'ImgBoxshow':'ImgBoxclose'"
                                            style="padding: 0 10px 0 0;"
                                            width="150"
                                            :src="d.image"
                                        />
                                    </div>
                                </template>
                                <div v-else>
                                    <!-- <n-image
                                        style="padding: 0 10px 0 0;"
                                        width="150"
                                        :src=""
                                    /> -->
                                </div>
                                <span class="TextBoxLine" style="display: flex;align-items: flex-end;" v-if="index == nowUserTalk.records.length-1 && isTalkView">_</span>
                            </div>
                            <div v-else>
                                <!-- <div v-if="item.recover===null || !item.recover">AI正在作答！请耐心等待...</div> -->
                                <div  v-for="(data,i) in item.recover.split('```')" :key="i">
                                    <template v-if="i%2==0" >
                                        <template v-for="(d,i) in data.split('|')" :key="i">
                                            <template v-if="i===1">
                                                <table style="display: contents;">
                                                    <template v-for="(ele,ind) in data.slice(data.indexOf('|'),data.lastIndexOf('|')).split('\n')" :key="ind">
                                                        <tr>
                                                            <template v-for="(el,keyInd) in ele.split('|')" :key="keyInd">
                                                                <template v-if="el!=='' && el!=='----'">
                                                                    <th  style="border: 1px solid;border-color: white;">{{el}}</th>
                                                                </template>
                                                            </template>
                                                        </tr>
                                                    </template>
                                                </table>
                                            </template>
                                            <template v-if="i===0 || i===data.split('|').length-1">
                                                <template v-for="(ele,ind) in d.split('\n')" :key="ind">
                                                    <br v-if="ind!==0"/>
                                                    {{ ele }}
                                                </template>
                                                <span class="TextBoxLine"  v-if="index == nowUserTalk.records.length-1 && isTalkView">_</span>
                                            </template>
                                        </template>
                                    </template>
                                    <div v-else-if="i%2==1" v-highlight style="position: relative;">
                                        <div class="" style="position: absolute;top: 22px;right: 0px;cursor: pointer;background-color: #232323;border-radius: 5px;padding: 10px;" @click="copy(data)">复制代码</div>
                                        <pre><code>//{{ data }}</code></pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="smallSanjiao"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showError" style="position: fixed; top: 40px; left: 50%; width: 300px; margin-left: -150px; z-index: 100000">
        <n-alert :type="errorValue.type">
        {{ errorValue.value }}
        </n-alert>
    </div>
</template>

<script>
import { NImage,NAlert } from "naive-ui";

export default {
    components: { NImage,NAlert },
    props: {
        nowUserTalk:Object,
        isTalkView:Boolean
    },
    watch: {
    },
    data() {
        return {
            nowTalkViewBox:[],
            showError: false,
            errorValue: {
                type: "success",
                value: "-----------",
            },
        }
    },
    mounted() {
        this.$refs.dialogueTalk.scrollTop = this.$refs.dialogueTalk.scrollHeight
    },
    methods: {
        copy(content) {
            //如果要保留文本格式，比如保留换行符，或者多行文本，可以使用  textarea 标签，再配和模板字符串 ` `
            const input = document.createElement('textarea')
            // 将想要复制的值
            input.value = content;
            // 页面底部追加输入框
            document.body.appendChild(input);
            // 选中输入框
            input.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            //this.$message.success('复制成功');
            // 复制后移除输入框
            input.remove();
            this.showError = true;
            this.errorValue = {
            type: "info",
            value: "复制成功！",
            };
            setTimeout(() => {
            this.showError = false;
            }, 1000);
        },
        scrollToBottom(){
            this.$refs.dialogueTalk.scrollTop = this.$refs.dialogueTalk.scrollHeight
        }
    },
}
</script>

<style scoped>
.ImgBoxshow{
    filter: blur(0px);
}
.ImgBoxclose{
    filter: blur(10px);
}
.dialogueTalk{
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 2%;
    padding-bottom: 20%;
    overflow: auto;
}
.MsgBox{
    width: 100%;
}
.userShowView{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.userShowView .infoPik{
    text-align: right;
    max-width: 80%;
    margin-right: 10px;
}

.userShowView .uerName{
    text-align: right;
}
.userShowView .userValue{
    padding: 20px;
    background-color: #5B5B5B;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    border-radius: 20px  0  20px 20px;
    display: inline-block;
    max-width: 100%;
    overflow-wrap: anywhere;
}
.userShowView .userValue .ImgBox{
    transition: 1s;
}
.userShowView .smallSanjiao{
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    right: -6px;
    border: 4px solid;
    border-color: #5B5B5B  transparent transparent #5B5B5B   ;
}
.userShowView .vlaueTime{
    text-align: right;
    color: #5B5B5B;
}

.systemShowView{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.systemShowView .infoPik{
    text-align: left;
    max-width: 80%;
    margin-left: 10px;
}
.systemShowView .uerName{
    text-align: left;
}
.systemShowView .userValue{
    padding: 20px;
    background-color: #5B5B5B;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    border-radius: 0 20px 20px 20px;
    display: inline-block;
    max-width: 100%;
    overflow-wrap: anywhere;
}
.systemShowView .smallSanjiao{
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: -6px;
    border: 4px solid;
    border-color: #5B5B5B  #5B5B5B transparent  transparent ;
}
.systemShowView .vlaueTime{
    text-align: left;
    color: #5B5B5B;
}
.TextBoxLine{
    animation: blink 1s infinite
}

@keyframes blink {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>